import React from "react";
import styled from "styled-components";
import { isString } from "@worksolutions/utils";

import { InternalIcons } from "primitives/Icon";

interface FieldItemInterface {
  children: InternalIcons | JSX.Element;
}

const StyledFieldItem = styled.div`
  line-height: 0;
`;

function FieldItem({ children }: FieldItemInterface) {
  return <StyledFieldItem>{isString(children) ? null : children}</StyledFieldItem>;
}

export default React.memo(FieldItem);
