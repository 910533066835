import * as yup from "yup";
import libPhoneNumber from "libphonenumber-js/min";

export const PASS_MIN_LENGTH = 6;

export const selectOptionValidator = yup.object({
  label: yup.string(),
  value: yup.string(),
});

export const emailValidator = yup.string().required("email.required").email("email.not_valid");

export const passwordValidator = yup.string().required("password.required").min(PASS_MIN_LENGTH, "password.min");
export const passwordConfirmValidator = yup
  .string()
  .required("password_repeat.required")
  .oneOf([yup.ref("password"), null!], "password_repeat.not_equal");

export const phoneValidator = yup
  .string()
  .test("libphonenumber", "Incorrect phone", (value) => {
    if (!value) return false;
    const phone = libPhoneNumber(value);
    if (!phone) return false;
    return phone.isValid();
  })
  .required();
