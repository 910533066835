import React from "react";
import { useBoolean } from "@worksolutions/react-utils";

export function useFocusedInputReaction(
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
) {
  const [focused, enableFocus, disableFocus] = useBoolean(false);

  const handleFocus = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      enableFocus();
      onFocus && onFocus(event);
    },
    [enableFocus, onFocus],
  );

  const handleBlur = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      disableFocus();
      onBlur && onBlur(event);
    },
    [disableFocus, onBlur],
  );

  return { focused, handleFocus, handleBlur };
}
