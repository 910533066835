import { Translate, TranslationQuery } from "next-translate";

export function getValidationText(
  t: Translate,
  { text, templates }: { text: string | undefined; templates?: TranslationQuery },
  namespace = "validations",
) {
  if (!text) return undefined;
  return t(`${namespace}:${text}`, templates);
}
