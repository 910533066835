import React from "react";
import InputField, { InputFieldInterface, InputFieldType } from "primitives/InputField";

import { useFocusedInputReaction } from "../InputField/hooks";
import TextFieldWrapper, { TextFieldWrapperInterface } from "./TextFieldWrapper";

export type TextFieldInterface = Omit<InputFieldInterface, "error" | "fieldType"> &
  Omit<TextFieldWrapperInterface, "children" | "fieldType" | "compactInputHeaderIsActive"> & {
    fieldType?: InputFieldType;
  };

function TextField(
  {
    label,
    required,
    errorMessage,
    hint,
    outerRef,
    fieldType = InputFieldType.LARGE,
    disabled,
    onFocus,
    onBlur,
    ...props
  }: TextFieldInterface,
  ref: React.Ref<HTMLDivElement>,
) {
  const { focused, handleFocus, handleBlur } = useFocusedInputReaction(onFocus, onBlur);

  return (
    <TextFieldWrapper
      label={label}
      required={required}
      errorMessage={errorMessage}
      hint={hint}
      disabled={disabled}
      outerRef={outerRef}
      {...(fieldType === InputFieldType.LARGE
        ? { fieldType: InputFieldType.LARGE }
        : { fieldType: InputFieldType.COMPACT, compactInputHeaderIsActive: focused || props.value !== "" })}
    >
      <InputField
        {...props}
        ref={ref}
        error={!!errorMessage}
        fieldType={fieldType}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </TextFieldWrapper>
  );
}

export default React.memo(React.forwardRef(TextField));
