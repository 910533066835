import styled from "styled-components";

const AuthForm = styled.form`
  background-color: white;
  box-shadow: 0 8px 64px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 28px 40px 44px 40px;
  width: 555px;
  position: relative;
`;

export default AuthForm;
