import styled, { css } from "styled-components";

import { InputFieldType } from "../enums";

export const StyledInputWrapper = styled.div<{
  fieldType: InputFieldType;
  error?: boolean;
  disabled?: boolean;
  hasLeftFieldItem: boolean;
  hasRightFieldItem: boolean;
  focused: boolean;
}>`
  box-shadow: none;
  display: flex;
  align-items: center;
  ${({ fieldType }) => matchFieldTypeAndFieldWrapperStyles[fieldType]}
  ${({ disabled, theme }) => disabled && `background-color: ${theme.colors.border}`}
`;

const largeInputWrapperStyles = css<{ hasLeftFieldItem: boolean; hasRightFieldItem: boolean; error?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  height: 50px;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.warning : theme.colors.border)};
  border-radius: 8px;
  padding: ${({ hasRightFieldItem, hasLeftFieldItem }) =>
    `0 ${hasRightFieldItem ? 8 : 16}px 0 ${hasLeftFieldItem ? 8 : 16}px`};
`;

const compactInputWrapperStyles = css<{ error?: boolean; focused: boolean }>`
  height: 40px;
  padding: 0;
  border-bottom: 1px solid ${({ theme, error }) => (error ? theme.colors.warning : theme.colors.border)};
  transition: 0.1s border-bottom;

  ${({ theme, error, focused }) => !error && focused && `border-bottom-color: ${theme.colors.primary}`};
`;

const matchFieldTypeAndFieldWrapperStyles: Record<InputFieldType, any> = {
  [InputFieldType.COMPACT]: compactInputWrapperStyles,
  [InputFieldType.LARGE]: largeInputWrapperStyles,
};
