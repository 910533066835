import React from "react";
import Head from "next/head";

import ContentLayout from "./ContentLayout";

interface AuthPageContainerInterface {
  browserPageTitle: string;
  children: React.ReactNode;
}

function AuthPageContainer({ browserPageTitle, children }: AuthPageContainerInterface) {
  return (
    <>
      <Head>
        <title>{browserPageTitle}</title>
      </Head>
      <ContentLayout>{children}</ContentLayout>
    </>
  );
}

export default React.memo(AuthPageContainer);
