import React from "react";
import { useField, useFormik } from "formik";
import { Schema } from "yup";
import { useSyncToRef } from "@worksolutions/react-utils";

interface CustomFormikIncomeInterface<T> {
  initialValues: T;
  validationSchema: Schema<any>;
  onSubmit(values: T): void;
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
  validateOnMount?: boolean;
  isInitialValid?: boolean;
}

export function useCustomFormik<T>({
  initialValues,
  validationSchema,
  onSubmit,
  validateOnBlur = false,
  validateOnChange = true,
  validateOnMount = false,
}: CustomFormikIncomeInterface<T>) {
  const formik = useFormik<T>({
    initialValues,
    validateOnChange,
    validateOnBlur,
    validationSchema,
    validateOnMount,
    onSubmit,
  });
  const formikRef = useSyncToRef(formik);
  const getFormik = React.useCallback(() => formikRef.current, [formikRef]);
  return [formik, getFormik] as const;
}

class FormikTypeExtractor<T> {
  wrapped(e: T) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useFormik<T>(null as any);
  }
}

export type FormikType<T extends Record<string, any>> = ReturnType<FormikTypeExtractor<T>["wrapped"]>;

export function useCustomField<VALUE = void>(fieldName: VALUE extends void ? "you need to pass generic" : string) {
  const [field, meta, helpers] = useField<VALUE>(fieldName);
  const helpersRef = useSyncToRef(helpers);
  const getHelpers = React.useCallback(() => helpersRef.current, [helpersRef]);
  return [field, meta, { helpers, getHelpers }] as const;
}
