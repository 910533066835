import styled from "styled-components";
import { typographyTypes } from "primitives/Typography";

export const StyledInput = styled.input<{ error?: boolean; center?: boolean }>`
  ${typographyTypes["body-regular"]};
  z-index: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  padding: 0;
  appearance: none;
  background-color: transparent;

  ${({ center }) => center && "text-align: center"};

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.disabled_text};
  }
`;
