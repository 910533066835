import React from "react";
import styled from "styled-components";

import Icon from "primitives/Icon";
import Typography from "primitives/Typography";

type Variant = "success" | "black";

type AuthHeaderInterface = {
  text: string;
  variant?: Variant;
  withArrow?: boolean;
  onArrowClick?(): void;
};

const Layout = styled.div`
  display: flex;
  align-items: center;
`;

const Arrow = styled.button.attrs({ type: "button" })`
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;
  margin-right: 20px;
  cursor: pointer;
  outline: none;

  &:focus svg path {
    stroke: ${(props) => props.theme.colors.black};
  }
`;

function AuthHeader({ text, withArrow, onArrowClick, variant = "black" }: AuthHeaderInterface) {
  return (
    <Layout>
      {withArrow && (
        <Arrow onClick={onArrowClick} tabIndex={0}>
          <Icon icon="arrow-left" />
        </Arrow>
      )}
      <Typography type="h2-bold" color={variant}>
        {text}
      </Typography>
    </Layout>
  );
}

export default React.memo(AuthHeader);
