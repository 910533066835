import React, { useMemo } from "react";
import { eventValue } from "@worksolutions/react-utils";

import { InternalIcons } from "primitives/Icon";
import Spinner from "primitives/Spinner";

import { InputFieldType } from "./enums";
import FieldItem from "./InternalComponents/FieldItem";
import { StyledInputWrapper } from "./InternalComponents/StyledInputWrapper";
import { StyledInput } from "./InternalComponents/StyledInput";
import { useFocusedInputReaction } from "./hooks";

export interface InputFieldInterface {
  center?: boolean;
  fieldType: InputFieldType;
  inputAs?: StyledComponentsAs<Omit<InputFieldInterface, "inputAs">>;
  autofocus?: boolean;
  value: string;
  name?: string;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  error?: boolean;
  fieldItemLeft?: InternalIcons | JSX.Element;
  fieldItemRight?: InternalIcons | JSX.Element;
  loading?: boolean;
  onChangeInput?: (value: string) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  inputRef?: React.Ref<HTMLInputElement>;
}

export * from "./enums";

const emptyOnChange = () => {};

function InputField(
  {
    inputRef,
    inputAs,
    fieldType,
    autofocus,
    value,
    placeholder,
    type,
    name,
    disabled,
    error,
    fieldItemRight,
    fieldItemLeft,
    loading,
    onChangeInput = emptyOnChange,
    onChange = emptyOnChange,
    onClick,
    onFocus,
    onBlur,
    ...props
  }: InputFieldInterface,
  ref: React.Ref<HTMLDivElement>,
) {
  const { focused, handleFocus, handleBlur } = useFocusedInputReaction(onFocus, onBlur);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event);
      eventValue(onChangeInput)(event);
    },
    [onChange, onChangeInput],
  );

  const hasRightFieldItem = !!loading || !!fieldItemRight;
  const shouldDisplayFieldItemLeft = useMemo(() => {
    if (!fieldItemLeft) return false;
    if (fieldType === InputFieldType.COMPACT && !value && !focused) return false;
    return true;
  }, [fieldItemLeft, fieldType, value, focused]);

  return (
    <StyledInputWrapper
      ref={ref}
      fieldType={fieldType}
      error={error}
      disabled={disabled}
      hasLeftFieldItem={!!fieldItemLeft}
      hasRightFieldItem={hasRightFieldItem}
      focused={focused}
    >
      {shouldDisplayFieldItemLeft && <FieldItem>{fieldItemLeft!}</FieldItem>}
      <StyledInput
        ref={inputRef}
        as={inputAs as any}
        error={error}
        name={name}
        type={type}
        autoFocus={autofocus}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        autoComplete="nope"
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={onClick}
        {...props}
      />
      {loading && (
        <FieldItem>
          <Spinner size="32px" />
        </FieldItem>
      )}
      {!loading && fieldItemRight && <FieldItem>{fieldItemRight}</FieldItem>}
    </StyledInputWrapper>
  );
}

export default React.memo(React.forwardRef(InputField));
