import React from "react";
import styled from "styled-components";

import { InputFieldType } from "../InputField";
import { CompactInputHeader, ErrorMessage, Hint, LargeInputHeader } from "./internal/header";

export type TextFieldWrapperInterface = {
  children: React.ReactNode;
  label?: string;
  required?: boolean;
  errorMessage?: string;
  hint?: string;
  outerRef?: React.Ref<HTMLDivElement>;
  disabled?: boolean;
} & ({ fieldType: InputFieldType.LARGE } | { fieldType: InputFieldType.COMPACT; compactInputHeaderIsActive: boolean });

const StyledTextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

function TextFieldWrapper({
  children,
  outerRef,
  errorMessage,
  hint,
  label,
  required,
  disabled,
  ...props
}: TextFieldWrapperInterface) {
  return (
    <StyledTextFieldWrapper ref={outerRef}>
      {label &&
        (props.fieldType === InputFieldType.LARGE ? (
          <LargeInputHeader required={required} disabled={disabled}>
            {label}
          </LargeInputHeader>
        ) : (
          <CompactInputHeader active={props.compactInputHeaderIsActive} required={required} disabled={disabled}>
            {label}
          </CompactInputHeader>
        ))}
      {children}
      {hint && !errorMessage && <Hint>{hint}</Hint>}
      {errorMessage && <ErrorMessage hasHint={!!hint}>{errorMessage}</ErrorMessage>}
    </StyledTextFieldWrapper>
  );
}

export default React.memo(TextFieldWrapper);
