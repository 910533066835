import React from "react";
import { useSyncToRef } from "@worksolutions/react-utils";
import { useLogEvent } from "hooks/analytics";
import { FormikType } from "hooks/useCustomFormik";

export function useAuthHandleSubmit(
  formik: FormikType<any>,
  { clickEventName, invalidFormEventName }: { clickEventName: string; invalidFormEventName: string },
) {
  const formikRef = useSyncToRef(formik);
  const [logEvent] = useLogEvent();

  return React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      formik.handleSubmit(event);
      logEvent(clickEventName);
      setTimeout(() => {
        if (formikRef.current.isValid) return;
        logEvent(invalidFormEventName, { error: "Validation error", code: -1 });
      }, 0);
    },
    [clickEventName, formik, formikRef, invalidFormEventName, logEvent],
  );
}
