import React from "react";
import styled from "styled-components";
import Typography from "primitives/Typography";
import TypographyLink from "primitives/Typography/TypographyLink";
import { config } from "configs";
import { urls } from "constants/urls";
import { useMerchantContext } from "store/merchantContext/hooks";
import { useLogEvent } from "hooks/analytics";
import LanguageSelector from "containers/LanguageSelector";
import { useTranslation } from "hooks/i18n";
import { formatPhone } from "utils/numbers";

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
  background-color: ${({ theme }) => theme.colors.appBackground};
  overflow: hidden;
  flex-direction: column;
`;

const Flash = styled.img`
  position: absolute;
  max-width: 100%;
  min-height: 714px;
  user-select: none;
`;

const LogoWrapper = styled.div`
  position: absolute;
  left: 140px;
  top: 40px;
  user-select: none;
  cursor: pointer;
`;

const Content = styled.div`
  position: relative;
`;

const HelperText = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 50px;
  text-align: center;
`;

const LanguageWrapper = styled.div`
  position: absolute;
  bottom: 35px;
  right: 32px;
`;

interface ContentLayoutInterface {
  children: React.ReactNode;
}

function ContentLayout({ children }: ContentLayoutInterface) {
  const merchantContext = useMerchantContext();
  const { t } = useTranslation();
  const [, logEventFabric] = useLogEvent();
  return (
    <Layout>
      <Flash src={config.authBgLogo} alt="logo" />
      <LogoWrapper>
        <TypographyLink href={urls.login.url}>
          <img src={config.authLogo} alt="logo" />
        </TypographyLink>
      </LogoWrapper>
      <Content>{children}</Content>
      <HelperText>
        <Typography color="tooltipBg" type="body-regular" inline>
          {t("layout:auth.support")}:{" "}
        </Typography>
        <TypographyLink
          type="body-medium"
          inline
          href={`mailto:${merchantContext.merchant_support_email}`}
          onClick={logEventFabric("Help_Email", { source: "auth" })}
        >
          {merchantContext.merchant_support_email}
        </TypographyLink>
        <Typography color="tooltipBg" type="body-regular" inline>
          {" "}
          {t("common:or")}{" "}
        </Typography>
        <TypographyLink
          type="body-medium"
          inline
          href={`tel:${merchantContext.merchant_support_phone}`}
          onClick={logEventFabric("Help_Call", { source: "auth" })}
        >
          {formatPhone(merchantContext.merchant_support_phone)}
        </TypographyLink>
      </HelperText>
      <LanguageWrapper>
        <LanguageSelector menuPlacement="top" />
      </LanguageWrapper>
    </Layout>
  );
}

export default React.memo(ContentLayout);
