import styled from "styled-components";
import { ThemeColors } from "styles/theme";

export interface AuthButtonInterface {
  variant?: ThemeColors;
  disabled?: boolean;
}

// TODO: Сделать обычной кнопкой
export default styled.button<AuthButtonInterface>`
  width: 100%;
  padding: 20px 0;
  border: none;
  border-radius: ${({ theme }) => theme.ui.radius_s};
  background-color: ${({ variant, theme }) => (variant ? theme.colors[variant] : theme.colors.primary)};
  color: ${(props) => props.theme.colors.white};
  font-weight: ${({ theme }) => theme.typography.fw_semi};
  cursor: pointer;
  text-align: center;
  appearance: none;
  outline: 0;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled_bg};
    color: ${({ theme }) => theme.colors.disabled_text};
    cursor: no-drop;
  }
`;
